.search-wrapper {
  background-color: #fff;
  display: flex;
  justify-content: flex-end;
  width: 50%;
  border-bottom: 1px solid gray;

  &__input {
    padding: 10px;
    border-radius: 0;
    border: 1px solid transparent;
    &:focus,
    &:active {
      border-radius: 0;
      border-color: transparent;
      box-shadow: inset 0px 0px 0px 0px gray;
    }
    &::placeholder {
      text-align: right;
    }
    &__text {
      background: transparent;
      border: transparent;
      .icon {
        color: gray;
        opacity: 0.8;
      }
    }
  }
}
